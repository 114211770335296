<template>
    <div>
        <!-- <v-dialog v-model="dialog" max-width="450px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon dark>
                  mdi-plus
                </v-icon>
                Create
              </v-btn>
            </template>
            <v-card tile>
              <v-card-title class="primary white--text">
                <span class="text-h5"><v-icon large color="white" class="mr-2">{{formIcon}}</v-icon>{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        outlined
                        dense
                        v-model="editedItem.title"
                        :error-messages="titleErrors"
                        required
                        @input="editedIndex == -1 ? $v.editedItem.title.$touch(): null"
                        @blur="editedIndex == -1 ? $v.editedItem.title.$touch(): null"
                        label="Title"
                        append-icon="mdi-rename-box"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        outlined
                        dense
                        v-model="editedItem.value"
                        :error-messages="valueErrors"
                        required
                        @input="editedIndex == -1 ? $v.editedItem.value.$touch(): null"
                        @blur="editedIndex == -1 ? $v.editedItem.value.$touch(): null"
                        label="Value"
                        append-icon="mdi-cash"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        outlined
                        dense
                        v-model="editedItem.minimum_price"
                        :error-messages="minimum_priceErrors"
                        required
                        @input="editedIndex == -1 ? $v.editedItem.minimum_price.$touch(): null"
                        @blur="editedIndex == -1 ? $v.editedItem.minimum_price.$touch(): null"
                        label="Minimum Price"
                        append-icon="mdi-cash"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-alert v-if="error" dense type="error">
                          {{error}}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-divider class="primary" />
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error"  @click="close">
                  Cancel
                </v-btn>
                <v-btn color="success"  @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog> -->

        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="error white--text"
                >
                    <v-icon color="white" class="mr-2">mdi-delete</v-icon>
                    You sure you want to delete this item?
                </v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="closeDelete"
                    >Cancel
                    </v-btn
                    >
                    <v-btn color="success" @click="deleteItemConfirm"
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table
                :headers="headers"
                :items="data"
                sort-by="calories"
                class="elevation-3"
        >
            <template v-slot:item.created_at="{ item }">
                <span> {{ moment(`${item.created_at}`).format('Do MMM YY')}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small @click="deleteItem(item)" class="error--text">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="secondary"
                ></v-progress-circular>
                <v-icon
                        v-else
                        x-large
                        color="grey lighten-1"
                >
                    mdi-tray-remove
                </v-icon>

            </template>
        </v-data-table>
    </div>
</template>
<script>
    import {required, numeric} from 'vuelidate/lib/validators'
    import {validationMixin} from 'vuelidate'
    import moment from 'moment'

    export default {
        name: 'Promos',
        mixins: [validationMixin],
        data: () => ({
            headers: [
                {text: 'Name', value: 'Customer_Name'},
                {text: 'Phone', value: 'Customer_Phone'},
                {text: 'State', value: 'Customer_State'},
                {text: 'Query', value: 'Customer_Query'},
                {text: 'Date', value: 'created_at'},
                {text: 'Actions', value: 'actions', sortable: false},
            ],
            data: [],
            apiUrl: process.env.VUE_APP_API_URL,
            loading: true,
            dialog: false,
            dialogDelete: false,
            error: '',
            editedIndex: -1,
            editedItem: {
                Sr_No: 0,
                Customer_Name: '',
                Customer_Phone: '',
                Customer_State: '',
                Customer_Query: ''
            },
            defaultItem: {
                Sr_No: 0,
                Customer_Name: '',
                Customer_Phone: '',
                Customer_State: '',
                Customer_Query: ''
            },
        }),
        created() {
            this.getPromos();
        },
        methods: {
            moment(m) {
                return moment(m);
            },
            editItem(item) {
                this.editedIndex = this.data.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.editedItem.img_url = null
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.data.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.deletePromo(this.editedItem.Sr_No)
                this.closeDelete();
            },
            close() {
                this.dialog = false;
                this.$v.$reset()
                this.error = ''
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            // save() {
            //   this.editedIndex == -1 ? this.addPromo() : this.updatePromo()
            // },
            //Promos API Calls
            getPromos() {
                this.loading = true
                this.$Axios
                    .get('get-contactFormEntries')
                    .then((res) => {
                        this.data = res.data["CustomerDetails"];
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            // addPromo() {

            //   this.error = ''
            //   this.$v.$touch()
            //   console.log(this.$v)
            //   if(this.$v.$error){
            //     return
            //   }

            //   let formData = new FormData();
            //   formData.append('title', this.editedItem.title);
            //   formData.append('value', this.editedItem.value);
            //   formData.append('minimum_price', this.editedItem.minimum_price);
            //   console.log('adding item : ', formData);
            //   this.$Axios({
            //     method: 'post',
            //     url: 'add-promocode',
            //     data: formData,
            //     headers: { 'Content-Type': 'multipart/form-data' },
            //   })
            //     .then(() => {
            //       this.getPromos();
            //       this.close();
            //     })
            //     .catch((e) => {
            //       console.log(e)
            //     });
            // },
            // updatePromo() {
            //   this.error = ''
            //   this.$v.$touch()

            //    if(this.$v.$error){
            //     return
            //   }
            //   let formData = new FormData();
            //   if (this.editedItem.title) formData.append('title', this.editedItem.title);
            //   if (this.editedItem.value) formData.append('value', this.editedItem.value);
            //   if (this.editedItem.minimum_price) formData.append('minimum_price', this.editedItem.minimum_price);
            //   console.log('adding item : ', formData);
            //   this.$Axios({
            //     method: 'post',
            //     url: 'update-promocode?id='  + this.editedItem.id,
            //     data: formData,
            //     headers: { 'Content-Type': 'multipart/form-data' },
            //   })
            //     .then(() => {
            //       this.getPromos();
            //       this.close();
            //     })
            //     .catch((e) => {
            //       console.log(e)
            //     });
            // },
            deletePromo(id) {
                this.$Axios
                    .post('delete-contact-entry?id=' + id)
                    .then(() => {
                        this.getPromos();
                    })
                    .catch((e) => {
                        console.log(e);
                    })
            },
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'New Promo Code' : 'Edit Promo Code';
            },
            formIcon() {
                return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
            },
            filtredItems() {
                if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter) || item.license.includes(this.filter) || item.email.includes(this.filter) || item.db.includes(this.filter))
                else return this.desserts
            },
            //// Vuelidate Errors
            titleErrors() {
                const errors = []
                if (!this.$v.editedItem.title.$dirty) return errors
                !this.$v.editedItem.title.required && errors.push('title is required !')
                return errors
            },
            valueErrors() {
                const errors = []
                if (!this.$v.editedItem.value.$dirty) return errors
                !this.$v.editedItem.value.required && errors.push('Value is required !')
                !this.$v.editedItem.value.numeric && errors.push('Value must be a number !')
                return errors
            },
            minimum_priceErrors() {
                const errors = []
                if (!this.$v.editedItem.minimum_price.$dirty) return errors
                !this.$v.editedItem.minimum_price.required && errors.push('Minimum price is required !')
                !this.$v.editedItem.minimum_price.numeric && errors.push('Minimum price be a number !')
                return errors
            },
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        validations: {
            editedItem: {
                title: {
                    required
                },
                value: {
                    required,
                    numeric
                },
                minimum_price: {
                    required,
                    numeric
                },
            },
        },
    };
</script>